import React from "react"
import SEO from "../components/seo"
import Layout from "../components/layout";
import { ParallaxProvider } from 'react-scroll-parallax';
import GridImg from '../components/NewCOmponents/officegrid'
import Clientlogo from '../components/NewCOmponents/clientlogo'
import styled from 'styled-components'
import Hgal from '../components/NewCOmponents/homecarousal'
const OfficePage = () => (
    <Layout>
      <SEO title="Office" />
<div style={{backgroundColor:"black", height:"100%"}} >


 <ParallaxProvider>
  <div  style={{backgroundColor:"black"}}>
<Hgal/>
<div className="container text-light py-5">

<Pwrap style={{fontSize:"1.4rem", fontFamily:"var(----HeadingFont)"}}> We offer design solutions that are tailored to accommodate the needs of your office space today and tomorrow.  Originality, functionality and on time delivery are the basic pillars on which our organization works. <br/>
We provide end to end solutions which include Air Conditioning, Fire system, Networking, Electrical works, Civil works, Painting & Wallpapers, Modular furniture & chairs, Carpet, Tiling, False ceiling, Designer Lighting, Cafeteria, Washroom & Plumbing works, Tensile Roofing and much more. 
</Pwrap>

</div>

<Clientlogo/>
<GridImg/>

</div> 






</ParallaxProvider>
</div>
    </Layout>
  )
  
  const Pwrap = styled.p`
  
  @media(max-width:960px){

    font-size:16px !important;
  }
  
  `
  
  export default OfficePage