import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import styled from 'styled-components'
import himg1 from '../../images/HomeCarousal/homeslide (1).jpg'
import himg2 from '../../images/HomeCarousal/homeslide (2).jpg'
import himg3 from '../../images/HomeCarousal/homeslide (3).jpg'
import himg4 from '../../images/HomeCarousal/homeslide (4).jpg'
import himg5 from '../../images/HomeCarousal/homeslide (5).jpg'
import himg6 from '../../images/HomeCarousal/homeslide (6).jpg'
import himg7 from '../../images/HomeCarousal/homeslide (7).jpg'



const galleryImage = (props) => {

  
let galleryh = [
    himg5,
    himg2,
    himg3,
    himg4,
    himg1,
    himg6,
    himg7
]




    return (
        <Ghwrap className="container-fluid  p-0" style={{backgroundColor:"black"}}>
        <Carousel showThumbs={false} autoPlay infiniteLoop interval={5000} transitionTime={550} stopOnHover={false}  width="100%">

{


    galleryh.map((item, index)=>{


return  <div key={index} >
            <img src={item}  width="100%"  />
        </div>



    }) 
}


    </Carousel>
</Ghwrap>
    );
};


const Ghwrap = styled.div`

.carousel .slider-wrapper{

height: 100% !important;


}


.carousel .slide img{

    height:150vh;
    overflow:hidden;
}

@media(max-width:500px){

    .carousel .slide img{

    height: 40vh !important;
}


    
}


@media(max-width:960px){

.carousel .slide img{

height: 70vh ;
}



}
`
export default galleryImage;