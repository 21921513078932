import React from 'react';
import styled from 'styled-components';
import {useStaticQuery,  graphql} from 'gatsby'
import Image from 'gatsby-image'


const OfficeGrid = (props) => {


    const data = useStaticQuery(graphql`
    query {
      allFile(filter: {
        extension: {regex: "/(jpg)|(jpeg)|(png)/"}, 
        sourceInstanceName: {eq: "officework"}}, sort: {fields: name}) 
      {
        edges {
          node {
            childImageSharp {
              fluid {
              originalName
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }`);




   const gdata = data.allFile.edges


    return (
        
      <OfficeGridWrap className="container-fluid py-sm-5 py-2" >
    <h2  className="py-2 text-center" style={{color:"var(--mainBlue)", textTransform:"uppercase"}}>Our office Portfolio</h2>

<div className="row">
    {
gdata.map((item, i)=>{

 return(<div className="col-sm-4 p-1 imgcont">
     {/* <img src={item.src} alt="images" width="100%"/> */}
     <Image fluid={item.node.childImageSharp.fluid} style={{width:"100%"}} />

 </div>)


})

    }
   
</div>
           {/* <Gallery rowHeight={320} images={IMAGES}/> */}
        



      </OfficeGridWrap>
    );
};

const OfficeGridWrap = styled.div`

position: relative;
background-color:black;
overflow:hidden;
.imgcont{
overflow:hidden;

img{

   
transition:0.5s ease-in-out !important;
  &:hover{

transform:scale(1.3);
}  
}


    
}

`
export default OfficeGrid;