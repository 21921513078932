import React from 'react'
import {useStaticQuery,  graphql} from 'gatsby'
import Image from 'gatsby-image'
import styled from 'styled-components'
export default function Clientlogo() {


const clientdata = useStaticQuery(graphql`

query  {
      allFile(filter: {
        extension: {regex: "/(jpg)|(jpeg)|(png)/"}, 
        sourceInstanceName: {eq: "clientlogos"}}, sort: {fields: size}) 
      {
        edges {
          node {
            childImageSharp {
              fluid {
              originalName
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }


`)


const clientlogo = clientdata.allFile.edges



    return (
        <ClientWrap className="container pb-2 pb-sm-5">
<h2 style={{color:"var(--mainBlue)", textTransform:"uppercase"}}>Our Client list</h2>
            <div className="row">

{

clientlogo.map((item, index)=>{

return( <div className="  col-sm-2 col-6 my-2 px-2" style={{opacity:0.95}} key={index}>
                    <Image fluid={item.node.childImageSharp.fluid}  alt="clientlogo" style={{width:"100%", backgroundColor:"white"}}/>
                </div>)

})
}




               
            </div>
            
        </ClientWrap>
    )
}


const ClientWrap = styled.div`



`